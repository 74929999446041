<template>
  <div
    class="fd-relative fd-flex fd-items-center fd-justify-end"
    @mouseenter="showActions = true"
    @mouseleave="showActions = false"
  >
    <div
      v-if="showActions"
      class="action fd-absolute fd-flex fd-items-center"
    >
      <span
        v-if="hasDoneIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-cursor-pointer"
        :class="[iconTotal > 1 ? 'fd-mr-2' : '']"
        @click="$emit('doneComponent')"
      >
        <SvgLoader :name="'doneIcon2'" />
      </span>

      <span
        v-if="hasCloseIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-cursor-pointer"
        :class="[iconTotal > 1 ? 'fd-mr-2' : '']"
        @click="$emit('closeComponent')"
      >
        <SvgLoader :name="'closeIcon2'" />
      </span>

      <span
        v-if="hasTickIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-cursor-pointer"
        :class="[iconTotal > 1 ? 'fd-mr-2' : '']"
        @click="$emit('tickComponent')"
      >
        <SvgLoader :name="'tickIcon2'" />
      </span>

      <span
        v-if="hasViewIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-cursor-pointer"
        :class="[iconTotal > 1 ? 'fd-mr-2' : '']"
        @click="$emit('viewComponent')"
      >
        <SvgLoader :name="'eyeIcon'" />
      </span>
      <span
        v-if="hasEditIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-cursor-pointer"
        :class="[iconTotal > 1 ? 'fd-mr-2' : '']"
        @click="$emit('showEditComponent')"
      >
        <SvgLoader :name="'editIcon'" />
      </span>
      <span
        v-if="hasSoldIcon"
        :class="[iconTotal > 1 ? 'fd-mr-2' : '']"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-block fd-cursor-pointer"
        @click="$emit('showSoldComponent')"
      >
        <SvgLoader :name="'soldHomeIcon'" />
      </span>
      <span
        v-if="hasReserveIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-block fd-cursor-pointer"
        @click="$emit('showReserveComponent')"
      >
        <SvgLoader :name="'reservedSignIcon'" />
      </span>
      <span
        v-if="hasRemoveIcon"
        class="fd-p-2 fd-bg-theme-8 fd-rounded-full fd-block fd-cursor-pointer"
        @click="$emit('showRemoveComponent')"
      >
        <SvgLoader :name="'removeIcon'" />
      </span>
    </div>
    <span class="fd-pl-5">
      <feather-icon :icon="verticalIcon ? 'MoreVerticalIcon' : 'MoreHorizontalIcon'" size="16" />
    </span>
  </div>
</template>

<script>
export default {
  name: "Actions",
  props: {
    verticalIcon: {
      type: Boolean,
      default: true
    },
    hasEditIcon: {
      type: Boolean,
      default: true
    },
    hasRemoveIcon: {
      type: Boolean,
      default: true
    },
    hasViewIcon: {
      type: Boolean,
      default: true
    },
    hasSoldIcon: {
      type: Boolean,
      default: false
    },
    hasReserveIcon: {
      type: Boolean,
      default: false
    },
    hasDoneIcon: {
      type: Boolean,
      default: false
    },
    hasCloseIcon: {
      type: Boolean,
      default: false
    },
    hasTickIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showActions: false
    }
  },
  computed: {
    iconTotal() {
      let total = 0
      if (this.hasEditIcon) { total = total + 1 }
      if (this.hasRemoveIcon) { total = total + 1 }
      if (this.hasViewIcon) { total = total + 1 }
      return total
    }
  }
}
</script>

<style lang="scss" scoped>
.action {
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 16px rgba(176, 176, 176, 0.25);
  backdrop-filter: blur(4px);
  border-radius: 19px;
  padding: 3px 4px;
}
</style>