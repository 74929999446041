<template>
  <div>
    <div v-if="$route.name === 'rsManagement.connected'">
      <div class="fd-w-full fd-flex fd-items-center fd-justify-between">
        <span class="fd-text-lg fd-font-bold fd-text-theme-2">{{ $t('connectedPage.listOfRs') }}</span>
        <button
          type="button"
          @click="invite"
          class="fd-flex fd-items-center fd-bg-theme-4 fd-text-white fd-rounded-lg fd-text-sm fd-font-medium fd-px-6 fd-py-2"
        >
          <span class="fd-text-lg fd-mt-px fd-mr-2 fd-font-thin">+</span>
          <span>{{ $t('connectedPage.inviteRs') }}</span>
        </button>
      </div>

      <div v-if="loading" class="fd-w-full fd-flex fd-items-center fd-justify-center">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      <div v-if="!loading" class="fd-mt-6">
        <div
          v-for="rs in listOfRs"
          :key="rs.id"
        >
          <RsCard :item="rs" />
        </div>
      </div>
    </div>
    <div v-if="$route.name !== 'rsManagement.connected'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import toast from "@/mixins/toast";
import RsCard from "@/views/components/RsManagement/RsCard";

export default {
  name: "Connected",
  components: {RsCard, BSpinner },
  mixins: [toast],
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.getData()
  },
  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name !== 'rsManagement.connected') return
        this.getData()
        this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      listOfRs: 'rs/listOfRs'
    }),
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('rs/getListOfRs').finally(() => {
        this.loading = false
      })
    },
    invite() {
      this.$router.push({ name: 'rsManagement.invite' })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>