<template>
  <div class="project-card">
    <div class="left">
      <div class="project-image fd-w-3/12">
        <img :src="getThumbnail" alt="thumbnail">
      </div>
      <div class="project-description fd-w-9/12">
        <div class="project-title fd-w-full fd-inline-flex fd-items-center fd-justify-between">
          <h1>{{ item.name }}</h1>
          <div @click="toggleStar" class="fd-cursor-pointer">
            <SvgLoader v-if="!item.is_favorite" :name="'starIcon'" />
            <SvgLoader v-if="item.is_favorite" :name="'starActiveIcon'" />
          </div>
        </div>

        <div class="project-agent-details fd-flex fd-items-center fd-justify-between fd-mr-12">
          <div class="fd-flex fd-items-center">
            <div class="fd-w-11 fd-h-11 fd-overflow-hidden fd-rounded-full">
              <img class="fd-w-11 fd-h-11" :src="'https://picsum.photos/id/1029/525/500'" :alt="item.contact_point.name">
            </div>
            <div class="fd-ml-3">
              <div class="fd-flex fd-items-center">
                <SvgLoader :name="'profileIcon'" />
                <span class="fd-text-xs fd-font-normal fd-font-normal fd-ml-1">{{ $t('global.name') }}</span>
              </div>
              <span class="fd-text-sm fd-font-semibold">{{ item.contact_point.name }}</span>
            </div>
          </div>
          <!--            <div class="fd-ml-3">-->
          <!--              <div class="fd-flex fd-items-center">-->
          <!--                <SvgLoader :name="'profileIcon'" />-->
          <!--                <span class="fd-text-xs fd-font-normal fd-ml-1">{{ $t('global.position') }}</span>-->
          <!--              </div>-->
          <!--              <span class="fd-text-sm fd-font-semibold">{{ item.contact_point.position }}</span>-->
          <!--            </div>-->
          <div class="fd-ml-3">
            <div class="fd-flex fd-items-center">
              <SvgLoader :name="'phoneIcon'" />
              <span class="fd-text-xs fd-font-normal fd-ml-1">{{ $t('global.phone') }}</span>
            </div>
            <span class="fd-text-sm fd-font-semibold">{{ item.contact_point.phone }}</span>
          </div>
          <div class="fd-ml-3">
            <div class="fd-flex fd-items-center">
              <SvgLoader :name="'emailIcon'" />
              <span class="fd-text-xs fd-font-normal fd-ml-1">{{ $t('global.email') }}</span>
            </div>
            <span class="fd-text-sm fd-font-semibold">{{ item.contact_point.email }}</span>
          </div>
        </div>

        <div class="fd-w-full fd-inline-flex fd-items-center fd-justify-between fd-pr-10">
          <div class="fd-w-1/3 statistic">
          <span class="fd-p-3 fd-rounded-full statistic-0">
              <SvgLoader name="soldIcon" />
            </span>
            <div>
              <span class="fd-block fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-bold">{{ item.total_sold_amount.dollar ? item.total_sold_amount.dollar : 0 }}</span>
              <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-regular">{{ $t('projectManagement.totalSold') }}</span>
            </div>
          </div>
          <div class="fd-w-1/3 statistic">
          <span class="fd-p-3 fd-rounded-full statistic-1">
              <SvgLoader name="reservedIcon" />
            </span>
            <div>
              <span class="fd-block fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-bold">{{ item.total_sold_count ? item.total_sold_count : 0 }}</span>
              <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-regular">{{ $t('projectManagement.totalSold') }}</span>
            </div>
          </div>
          <div class="fd-w-1/3 statistic">
          <span class="fd-p-3 fd-rounded-full statistic-2">
              <SvgLoader name="availableIcon" />
            </span>
            <div>
              <span class="fd-block fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-bold">{{ item.total_commission.dollar ? item.total_commission.dollar : 0 }}</span>
              <span class="fd-pl-2 fd-text-xs fd-text-theme-6 fd-font-regular">{{ $t('projectManagement.totalAvailable') }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="right">
      <div class="fd-w-2/3 fd-border-l fd-ml-7">
        <div class="avatars fd-w-full fd-h-full fd-flex fd-items-center fd-justify-center">
          <div>
            <div
              v-for="(agent, index) in item.members"
              v-if="index <= 7"
              :key="index"
              class="avatar"
            >
          <span
            v-if="index === 7 || index === (item.members.length - 1)"
            class="fd-absolute fd-left-0 fd-top-0 fd-w-full fd-h-full fd-flex fd-items-center fd-justify-center fd-text-sm fd-text-theme-2"
            style="background-color: rgba(255,255,255, .77)"
          >
            {{ getAgentsCount }}
          </span>
              <img :src="agent === 1 ? agent : defaultImage" alt="profile image">
            </div>
          </div>
        </div>
      </div>
      <div
        class="fd-w-1/3 fd-flex fd-items-center fd-justify-center fd-h-full fd-bg-theme-8 fd-cursor-pointer hover:fd-opacity-80"
        style="border-color: #EBEEEC"
        @click="goTo"
      >
        <div>
          <feather-icon class="fd-text-theme-4" size="25" icon="ChevronRightIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Actions from "@/views/components/global/Actions";
export default {
  name: "RsCard",
  components: {Actions},
  data() {
    return {
      rsTop: 0,
      agentTop: 0,
      showActions: false,
      defaultImage: null
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  created() {
    this.defaultImage = require('@/assets/images/pages/project/user-default.jpeg')
  },
  computed: {
    getThumbnail() {
      return this.item.thumbnail ? this.item.thumbnail : require('@/assets/images/pages/project/default-image.png')
    },
    getAgentsCount() {
      if ('member_count' in this.item && this.item.member_count > 0) {
        if (this.item.member_count === 1) {
          return 1
        }
        return this.item.member_count + '+'
      }
      return 0
    }
  },
  methods: {
    getName(item) {
      return item.first_name + ' ' + item.last_name
    },
    toggleStar() {
      this.$store.dispatch('rs/toggleStar', this.item.id)
    },
    goTo() {
      return this.$router.push({ name: 'rsManagement.projects', params: { id: this.item.id }})
    }
  }
}
</script>

<style lang="scss" scoped>
.project-card {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: stretch;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 0;
  margin-bottom: 20px;
  overflow: hidden;

  & .left {
    width: 87%;
    height: 200px;
    display: flex;
    align-items: stretch;
    padding: 18px 0 18px 18px;
  }

  & .right {
    width: 13%;
    height: 200px;
    display: flex;
    align-items: stretch;
  }

  @media screen and (min-width: 1575px) {
    & .left {
      width: 86%;
    }
    & .right {
      width: 14%;
    }
  }
  @media screen and (min-width: 1653px) {
    & .left {
      width: 88%;
    }
    & .right {
      width: 12%;
    }
  }

  & div.project-image {
    min-width: 218px;

    & img {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & div.project-description {
    margin-left: 16px;

    & h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #202A21;
      margin-bottom: 7px !important;
    }
    & div.project-agent-details {
      margin-top: 13px;
      margin-bottom: 20px;
    }
  }
}
.avatar {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 36px;
  height: 34px;
  border: 2px solid #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.14);
  margin-right: auto;
  margin-left: auto;
}
.avatar:not(:first-child) {
  margin-top: -15px;
}

.avatar img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.profile--image {
  width: 32px;
  height: 30px;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.14);
  border: 2px solid #ffffff;

  img {
    width: 32px;
    height: 30px;
    object-fit: cover;
  }
}
.statistic {
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &-0 {
    background-color: #E5F8EE;
  }
  &-1 {
    background-color: #FFF3E8;
  }
  &-2 {
    background-color: #DEF9FD;
  }

}
</style>